import { FileRoute, redirect } from '@tanstack/react-router'

import { isHasUserAccessToken } from '@/utils/app'

export const Route = new FileRoute('/').createRoute({
  beforeLoad: ({ location }) => {
    if (!isHasUserAccessToken()) {
      throw redirect({
        to: '/auth/login',
        search: {
          redirect: location.href !== '/' ? location.href : undefined,
        },
      })
    }

    throw redirect({
      to: '/dashboard',
    })
  },
})
