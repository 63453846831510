import { FileRoute, lazyRouteComponent } from '@tanstack/react-router'

import signed from '@/middlewares/signed'
import validateParams from '@/middlewares/validate-params'

export const Route = new FileRoute('/promoters/archives/$promoterId').createRoute({
  component: lazyRouteComponent(() => import('@/modules/promoter/archive-detail')),
  beforeLoad: ({ location, params, navigate }) => {
    signed({ location })

    validateParams(params, navigate, `/promoters/archives/${params.promoterId}`)
  },
})
