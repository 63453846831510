import { createApi } from '@reduxjs/toolkit/query/react'

import { ADMIN_SETTING_GENERAL } from '@/config/endpoints'
import { SettingDetailResponse, SettingUpdateRequest } from '@/types/setting'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'setting',
  tagTypes: ['Setting'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getGeneralSetting: builder.query<SettingDetailResponse, void>({
      query: () => ({
        ...composeRequest({}, ADMIN_SETTING_GENERAL),
        method: 'GET',
      }),
      providesTags: ['Setting'],
    }),
    updateGeneralSetting: builder.mutation<SettingDetailResponse, SettingUpdateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_SETTING_GENERAL),
        method: 'PUT',
      }),
      invalidatesTags: ['Setting'],
    }),
  }),
})

// Export hooks for usage in functional components
export const { useLazyGetGeneralSettingQuery, useUpdateGeneralSettingMutation, util: settingUtil } = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
