import { createApi } from '@reduxjs/toolkit/query/react'

import { BrandShop, ChannelType, ExclusivityFlag, Partner, StoreStatus } from '@/config/constants'
import {
  ADMIN_STORE,
  ADMIN_STORE_CLOSED,
  ADMIN_STORE_DETAIL,
  ADMIN_STORE_REACTIVATE,
  ADMIN_STORE_RESEND,
  STORE_ACCOUNT,
} from '@/config/endpoints'
import { LOCATION } from '@/config/endpoints'
import { BaseDetailRequest, BaseErrorResponse, BaseRequest } from '@/types/common'
import { LocationListResponse } from '@/types/location'
import {
  StoreBulkCreateResponse,
  StoreBulkCreateResponseData,
  StoreCloseRequest,
  StoreCreateRequest,
  StoreCreateRequestData,
  StoreDetailResponse,
  StoreListRequest,
  StoreListResponse,
  StoreReactivateRequest,
  StoreUpdateRequest,
} from '@/types/store'
import {
  ListLocationStoreAccountExportResponse,
  LocationStoreAccountFiltersRequest,
  StoreAccountListResponse,
} from '@/types/store-account'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'store',
  tagTypes: ['Store'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    createStore: builder.mutation<void, StoreCreateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_STORE),
        method: 'POST',
      }),
      invalidatesTags: ['Store'],
    }),
    getListStore: builder.query<StoreListResponse, StoreListRequest>({
      query: (request) => {
        const newRequest = { ...request }

        if (request.query && request.query.filters && !request.query.filters.storeStatusId) {
          newRequest.query = {
            ...request.query,
            filters: {
              ...request.query.filters,
              storeStatusId: [
                String(StoreStatus.ACTIVE),
                String(StoreStatus.LOW_ACTIVE),
                String(StoreStatus.INACTIVE),
              ].join(','),
            },
          }
        }

        return {
          ...composeRequest(newRequest, ADMIN_STORE),
          method: 'GET',
        }
      },
      providesTags: ['Store'],
    }),
    getListArchiveStore: builder.query<StoreListResponse, StoreListRequest>({
      query: (request) => {
        const newRequest = { ...request }

        if (request.query && request.query.filters && !request.query.filters.storeStatusId) {
          newRequest.query = {
            ...request.query,
            filters: {
              ...request.query.filters,
              storeStatusId: String(StoreStatus.CLOSED),
            },
          }
        }

        return {
          ...composeRequest(newRequest, ADMIN_STORE),
          method: 'GET',
        }
      },
      providesTags: ['Store'],
    }),
    getDetailStore: builder.query<StoreDetailResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_STORE_DETAIL),
        method: 'GET',
      }),
    }),
    updateStore: builder.mutation<void, StoreUpdateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_STORE_DETAIL),
        method: 'PUT',
      }),
      invalidatesTags: ['Store'],
    }),
    closeStore: builder.mutation<void, StoreCloseRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_STORE_CLOSED),
        method: 'PUT',
      }),
      invalidatesTags: ['Store'],
    }),
    reactivateStore: builder.mutation<void, StoreReactivateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_STORE_REACTIVATE),
        method: 'PUT',
      }),
      invalidatesTags: ['Store'],
    }),
    resendEmailStore: builder.mutation<void, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_STORE_RESEND),
        method: 'POST',
      }),
    }),
    bulkCreateStore: builder.mutation<StoreBulkCreateResponse, StoreCreateRequest[]>({
      queryFn: async (items, api, extraOptions, baseQuery) => {
        const responses: StoreBulkCreateResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: [],
        }

        for (const item of items) {
          const idx = items.indexOf(item)
          const data = item.data as StoreCreateRequestData
          const response: StoreBulkCreateResponseData = {} as StoreBulkCreateResponseData

          switch (data.exclusivityFlag.id.toString().toUpperCase().trim()) {
            case 'MULTI BRAND':
              data.exclusivityFlag.id = ExclusivityFlag.MULTI_BRAND
              break
            case 'SAMSUNG EXCLUSIVE':
              data.exclusivityFlag.id = ExclusivityFlag.SAMSUNG_EXCLUSIVE
              break
          }

          switch (data.channelType.id.toString().toUpperCase().trim()) {
            case 'MODERN CHANNEL':
              data.channelType.id = ChannelType.MODERN_CHANNEL
              break
            case 'TRADITIONAL':
              data.channelType.id = ChannelType.TRADITIONAL
              break
          }

          switch (data.brandShop.id.toString().toUpperCase().trim()) {
            case 'SAMSUNG EXPERIENCE STORE':
              data.brandShop.id = BrandShop.SAMSUNG_EXPERIENCE_STORE
              break
            case 'SAMSUNG EXPERIENCE STORE PARTNER':
              data.brandShop.id = BrandShop.SAMSUNG_EXPERIENCE_STORE_PARTNER
              break
            case 'SHOP IN SHOP':
              data.brandShop.id = BrandShop.SHOP_IN_SHOP
              break
            case 'NORMAL STORE':
              data.brandShop.id = BrandShop.NORMAL_STORE
              break
            case 'WINNING ZONE':
              data.brandShop.id = BrandShop.WINNING_ZONE
              break
          }

          if (data.partners) {
            data.partners = data.partners.map((partner) => {
              switch (partner.toString().toUpperCase().trim()) {
                case 'ENB':
                  return { id: Partner.ENB }
                case 'LAKU6':
                  return { id: Partner.LAKU6 }
                case 'COMPASIA':
                  return { id: Partner.COMPASIA }
                default:
                  return { id: Partner.UNKNOWN }
              }
            })
          }

          const { error } = await baseQuery({
            ...composeRequest({ data }, ADMIN_STORE),
            method: 'POST',
          })

          if (error) {
            response.row = idx + 1
            response.item = item.data as StoreCreateRequestData
            response.error = error.data as BaseErrorResponse

            responses.data.push(response)
          }
        }

        if (responses.data.length > 0) {
          responses.success = false
        }

        return { data: responses }
      },
      invalidatesTags: ['Store'],
    }),
    exportListStore: builder.query<StoreListResponse, StoreListRequest>({
      queryFn: async (request, _api, extraOptions, baseQuery) => {
        const data: StoreListResponse = {
          success: true,
          code: 'OK',
          message: '',
          data: {
            items: [],
            metadata: {
              skip: '0',
              limit: '0',
              sortBy: 'latest',
              count: '0',
            },
          },
        }

        const newRequest: StoreListRequest = { ...request }

        if (request.query && request.query.filters && !request.query.filters.storeStatusId) {
          newRequest.query = {
            ...request.query,
            filters: {
              ...request.query.filters,
              storeStatusId: [
                String(StoreStatus.ACTIVE),
                String(StoreStatus.LOW_ACTIVE),
                String(StoreStatus.INACTIVE),
              ].join(','),
            },
          }
        }

        if (request.query) {
          newRequest.query = {
            ...newRequest.query,
            limit: Number.MAX_SAFE_INTEGER,
          }
        }

        const limit = 100
        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const response = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit,
                    skip,
                  },
                },
                ADMIN_STORE
              ),
              method: 'GET',
            })

            const {
              data: { items },
            } = response.data as StoreListResponse

            items.forEach((item) => {
              data.data.items.push(item)
            })

            skip += limit
            hasMore = items.length > 0
          } catch (e) {
            console.error(e)
          }
        }

        return { data }
      },
    }),
    exportListLocationStoreAccount: builder.query<
      ListLocationStoreAccountExportResponse,
      BaseRequest<unknown, LocationStoreAccountFiltersRequest>
    >({
      queryFn: async (request, api, extraOptions, baseQuery) => {
        const response: ListLocationStoreAccountExportResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: {
            location: [],
            storeAccount: [],
          },
        }

        const newRequest = { ...request }
        const limit = 100

        // location
        let skipLocation = 0
        let hasMoreLocation = true

        while (hasMoreLocation) {
          try {
            const locationResponse = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit,
                    skip: skipLocation,
                  },
                },
                LOCATION
              ),
              method: 'GET',
            })

            const {
              data: { items },
            } = locationResponse.data as LocationListResponse

            items.forEach((item) => {
              response.data.location.push(item)
            })

            skipLocation += limit
            hasMoreLocation = items.length > 0
          } catch (e) {
            console.error(e)
          }
        }

        // store account
        let skipStoreAccount = 0
        let hasMoreStoreAccount = true

        while (hasMoreStoreAccount) {
          try {
            const storeAccountResponse = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit,
                    skip: skipStoreAccount,
                  },
                },
                STORE_ACCOUNT
              ),
              method: 'GET',
            })

            const {
              data: { items },
            } = storeAccountResponse.data as StoreAccountListResponse

            items.forEach((item) => {
              response.data.storeAccount.push(item)
            })

            skipStoreAccount += limit
            hasMoreStoreAccount = items.length > 0
          } catch (e) {
            console.error(e)
          }
        }

        return { data: response }
      },
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useCreateStoreMutation,
  useLazyGetListStoreQuery,
  useLazyGetListArchiveStoreQuery,
  useLazyGetDetailStoreQuery,
  useUpdateStoreMutation,
  useCloseStoreMutation,
  useReactivateStoreMutation,
  useResendEmailStoreMutation,
  useBulkCreateStoreMutation,
  useLazyExportListStoreQuery,
  useLazyExportListLocationStoreAccountQuery,
  util: storeUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
