import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Admin } from '@/types/admin'

type UserState = {
  data?: Admin
}

const initialState: UserState = {}

export const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    setUserData: (state, action: PayloadAction<Admin>) => {
      state.data = action.payload
    },
    clearUserData: (state) => {
      state.data = undefined
    },
  },
})

export const { setUserData, clearUserData } = userSlice.actions

export default userSlice.reducer
