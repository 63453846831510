import { FileRoute, lazyRouteComponent } from '@tanstack/react-router'

import signed from '@/middlewares/signed'
import validateParams from '@/middlewares/validate-params'

export const Route = new FileRoute('/trade-in/$tradeInId/').createRoute({
  component: lazyRouteComponent(() => import('@/modules/trade-in/detail')),
  beforeLoad: ({ location, params, navigate }) => {
    signed({ location })

    validateParams(params, navigate, `/devices/${params.tradeInId}`)
  },
})
