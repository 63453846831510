import Cookies from 'js-cookie'

import { APP_ACCESS_TOKEN_KEY_NAME, USER_ACCESS_TOKEN_KEY_NAME, USER_REFRESH_TOKEN_KEY_NAME } from '@/config/token'

const expiredAtToSeconds = (expiredAt: string) => {
  const expire = new Date(0)

  return expire.setUTCSeconds(parseInt(expiredAt))
}

export const getAppAccessToken = () => Cookies.get(APP_ACCESS_TOKEN_KEY_NAME)
export const getUserAccessToken = () => Cookies.get(USER_ACCESS_TOKEN_KEY_NAME)
export const getUserRefreshToken = () => Cookies.get(USER_REFRESH_TOKEN_KEY_NAME)

export const setAppAccessToken = (token: string, expiredAt: string) => {
  Cookies.set(APP_ACCESS_TOKEN_KEY_NAME, token, { expires: expiredAtToSeconds(expiredAt) })
}
export const setUserAccessToken = (token: string, expiredAt: string) => {
  Cookies.set(USER_ACCESS_TOKEN_KEY_NAME, token, { expires: expiredAtToSeconds(expiredAt) })
}
export const setUserRefreshToken = (token: string, expiredAt: string) => {
  Cookies.set(USER_REFRESH_TOKEN_KEY_NAME, token, { expires: expiredAtToSeconds(expiredAt) })
}

export const isHasAppAccessToken = () => getAppAccessToken() !== undefined
export const isHasUserAccessToken = () => getUserAccessToken() !== undefined
export const isHasUserRefreshToken = () => getUserRefreshToken() !== undefined

export const removeAuthUserCookies = () => {
  Cookies.remove(USER_ACCESS_TOKEN_KEY_NAME)
  Cookies.remove(USER_REFRESH_TOKEN_KEY_NAME)
}
