import admin from '@/services/admin'
import auth from '@/services/auth'
import brand from '@/services/brand'
import dashboard from '@/services/dashboard'
import device from '@/services/device'
import deviceCategory from '@/services/device-category'
import localPartner from '@/services/local-partner'
import location from '@/services/location'
import promoter from '@/services/promoter'
import promotion from '@/services/promotion'
import role from '@/services/role'
import setting from '@/services/setting'
import store from '@/services/store'
import storeAccount from '@/services/store-account'
import termCondition from '@/services/term-condition'
import tradeIn from '@/services/trade-in'
import wearable from '@/services/wearable'

const rootServices = {
  reducers: {
    // auth
    [auth.reducerPath]: auth.reducer,

    // admin
    [admin.reducerPath]: admin.reducer,

    // role
    [role.reducerPath]: role.reducer,

    // location
    [location.reducerPath]: location.reducer,

    // store
    [store.reducerPath]: store.reducer,
    [storeAccount.reducerPath]: storeAccount.reducer,

    // promoter
    [promoter.reducerPath]: promoter.reducer,

    // promotion
    [promotion.reducerPath]: promotion.reducer,

    // device
    [device.reducerPath]: device.reducer,
    [deviceCategory.reducerPath]: deviceCategory.reducer,

    // tradeIn
    [tradeIn.reducerPath]: tradeIn.reducer,

    // term condition
    [termCondition.reducerPath]: termCondition.reducer,

    // setting
    [setting.reducerPath]: setting.reducer,

    // dashboard
    [dashboard.reducerPath]: dashboard.reducer,

    // local partner
    [localPartner.reducerPath]: localPartner.reducer,

    // brand
    [brand.reducerPath]: brand.reducer,

    // wearable
    [wearable.reducerPath]: wearable.reducer,
  },
  middlewares: [
    // auth
    auth.middleware,

    // admin
    admin.middleware,

    // role
    role.middleware,

    // location
    location.middleware,

    // promoter
    promoter.middleware,

    // promotion
    promotion.middleware,

    // store
    store.middleware,
    storeAccount.middleware,

    // device
    device.middleware,
    deviceCategory.middleware,

    // tradeIn
    tradeIn.middleware,

    // term condition
    termCondition.middleware,

    // setting
    setting.middleware,

    // dashboard
    dashboard.middleware,

    // local partner
    localPartner.middleware,

    // brand
    brand.middleware,

    // wearable
    wearable.middleware,
  ],
}

export default rootServices
