import { createApi } from '@reduxjs/toolkit/query/react'

import { STORE_ACCOUNT } from '@/config/endpoints'
import { StoreAccountListRequest, StoreAccountListResponse } from '@/types/store-account'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'storeAccount',
  tagTypes: ['StoreAccount'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getListStoreAccount: builder.query<StoreAccountListResponse, StoreAccountListRequest>({
      query: (request) => ({
        ...composeRequest(request, STORE_ACCOUNT),
        method: 'GET',
      }),
      providesTags: ['StoreAccount'],
    }),
  }),
})

// Export hooks for usage in functional components
export const { useLazyGetListStoreAccountQuery, util: storeAccountUtil } = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
