import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { BaseListRequest } from '@/types/common'

type DownloadState = {
  isOpen: boolean
  isLoading: boolean
  query: BaseListRequest
}

const initialState: DownloadState = {
  isOpen: false,
  isLoading: false,
  query: {
    skip: 0,
    limit: Number.MAX_SAFE_INTEGER,
    sortBy: 'latest',
  },
}

export const uploadSlice = createSlice({
  initialState,
  name: 'download',
  reducers: {
    setDownloadIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
    setDownloadIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setDownloadQuery: (state, action: PayloadAction<BaseListRequest>) => {
      state.query = action.payload
    },
    clearDownloadCQuery: (state) => {
      state.query = {
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        sortBy: 'latest',
      }
    },
  },
})

export const { setDownloadIsOpen, setDownloadIsLoading, setDownloadQuery, clearDownloadCQuery } = uploadSlice.actions

export default uploadSlice.reducer
