import { DateTime } from 'luxon'

export const tailingSlash = (url: string) => url.replace(/\/$/, '')

export const formatEpoch = (seconds: number, format = 'dd MMMM yyyy HH:mm') =>
  DateTime.fromSeconds(seconds).toFormat(format)

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const formatItems = (idKey: string, labelKey: string, items: Array<any>) => {
  return items.map((item) => ({ id: String(item[idKey]), label: item[labelKey] }))
}

export const formatCurrency = (number: number) =>
  new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number)

export const addDotsToNumber = (value: string | number) => formatCurrency(Number(String(value).replace(/[,.]/g, '')))

export const formatNumber = (number: number) =>
  new Intl.NumberFormat('id-ID', { minimumFractionDigits: 0 }).format(number)

export const sortingItems = (data: Array<any>) => data.sort((a, b) => a.sort - b.sort)

export const jsDateToEpochWithOffset = (date: Date) =>
  DateTime.fromJSDate(date).plus({ minutes: DateTime.now().offset }).toSeconds().toFixed(0)
