import { createApi } from '@reduxjs/toolkit/query/react'

import { LOCATION } from '@/config/endpoints'
import { LocationListRequest, LocationListResponse } from '@/types/location'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'location',
  tagTypes: ['Location'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getListLocation: builder.query<LocationListResponse, LocationListRequest>({
      query: (request) => ({
        ...composeRequest(request, LOCATION),
        method: 'GET',
      }),
      providesTags: ['Location'],
    }),
  }),
})

// Export hooks for usage in functional components
export const { useLazyGetListLocationQuery, util: locationUtil } = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
