import { createApi } from '@reduxjs/toolkit/query/react'

import { TERM_AND_CONDITION } from '@/config/endpoints'
import { TermConditionDetailResponse, TermConditionUpdateRequest } from '@/types/term-condition'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'termCondition',
  tagTypes: ['TermCondition'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getTermCondition: builder.query<TermConditionDetailResponse, void>({
      query: () => ({
        ...composeRequest({}, TERM_AND_CONDITION),
        method: 'GET',
      }),
      providesTags: ['TermCondition'],
    }),
    updateTermCondition: builder.mutation<void, TermConditionUpdateRequest>({
      query: (request) => ({
        ...composeRequest(request, TERM_AND_CONDITION),
        method: 'PUT',
      }),
      invalidatesTags: ['TermCondition'],
    }),
  }),
})

// Export hooks for usage in functional components
export const { useLazyGetTermConditionQuery, useUpdateTermConditionMutation, util: termConditionUtil } = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
