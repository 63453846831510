import { FileRoute, lazyRouteComponent } from '@tanstack/react-router'

import signed from '@/middlewares/signed'
import validateParams from '@/middlewares/validate-params'

export const Route = new FileRoute('/stores/$storeId/edit').createRoute({
  component: lazyRouteComponent(() => import('@/modules/store/edit')),
  beforeLoad: ({ location, params, navigate }) => {
    signed({ location })

    validateParams(params, navigate, `/stores/${params.storeId}/edit`)
  },
})
