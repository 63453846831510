import { createApi } from '@reduxjs/toolkit/query/react'

import {
  AUTH_ANONYMOUS,
  AUTH_CHANGE_PASSWORD,
  AUTH_FORGOT_PASSWORD,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_RESET_PASSWORD,
  AUTH_RESET_PASSWORD_STORE,
  AUTH_RESET_PIN_PROMOTER,
  AUTH_SETUP_PASSWORD,
  AUTH_SETUP_PASSWORD_STORE,
  AUTH_SETUP_PIN_PROMOTER,
  AUTH_VALIDATE_TOKEN,
} from '@/config/endpoints'
import {
  AuthAnonymousSessionRequest,
  AuthAnonymousSessionResponse,
  AuthChangePasswordRequest,
  AuthChangePasswordResponse,
  AuthForgotPasswordRequest,
  AuthForgotPasswordResponse,
  AuthLoginRequest,
  AuthLoginResponse,
  AuthPasswordRequest,
  AuthPasswordResponse,
  AuthPinRequest,
  AuthPinResponse,
  AuthValidateTokenRequest,
  AuthValidateTokenResponse,
} from '@/types/auth'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'auth',
  tagTypes: ['Auth'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    anonymous: builder.mutation<AuthAnonymousSessionResponse, AuthAnonymousSessionRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_ANONYMOUS),
        method: 'POST',
      }),
    }),
    login: builder.mutation<AuthLoginResponse, AuthLoginRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_LOGIN),
        method: 'POST',
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        ...composeRequest({}, AUTH_LOGOUT),
        method: 'DELETE',
      }),
    }),
    forgotPassword: builder.mutation<AuthForgotPasswordResponse, AuthForgotPasswordRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_FORGOT_PASSWORD),
        method: 'POST',
      }),
    }),
    resetPassword: builder.mutation<AuthPasswordResponse, AuthPasswordRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_RESET_PASSWORD),
        method: 'POST',
      }),
    }),
    setupPassword: builder.mutation<AuthPasswordResponse, AuthPasswordRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_SETUP_PASSWORD),
        method: 'POST',
      }),
    }),
    changePassword: builder.mutation<AuthChangePasswordResponse, AuthChangePasswordRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_CHANGE_PASSWORD),
        method: 'PUT',
      }),
    }),
    validateToken: builder.mutation<AuthValidateTokenResponse, AuthValidateTokenRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_VALIDATE_TOKEN),
        method: 'POST',
      }),
    }),
    setupPasswordStore: builder.mutation<AuthPasswordResponse, AuthPasswordRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_SETUP_PASSWORD_STORE),
        method: 'POST',
      }),
    }),
    resetPasswordStore: builder.mutation<AuthPasswordResponse, AuthPasswordRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_RESET_PASSWORD_STORE),
        method: 'POST',
      }),
    }),
    setupPinPromoter: builder.mutation<AuthPinResponse, AuthPinRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_SETUP_PIN_PROMOTER),
        method: 'POST',
      }),
    }),
    resetPinPromoter: builder.mutation<AuthPinResponse, AuthPinRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_RESET_PIN_PROMOTER),
        method: 'POST',
      }),
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useAnonymousMutation,
  useLoginMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useSetupPasswordMutation,
  useChangePasswordMutation,
  useValidateTokenMutation,
  useResetPasswordStoreMutation,
  useSetupPasswordStoreMutation,
  useResetPinPromoterMutation,
  useSetupPinPromoterMutation,
  util: authUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
