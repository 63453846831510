import { FileRoute, lazyRouteComponent } from '@tanstack/react-router'

import signed from '@/middlewares/signed'
import validateParams from '@/middlewares/validate-params'

export const Route = new FileRoute('/promoters/$promoterId/edit').createRoute({
  component: lazyRouteComponent(() => import('@/modules/promoter/edit')),
  beforeLoad: ({ location, params, navigate }) => {
    signed({ location })

    validateParams(params, navigate, `/promoters/${params.promoterId}/edit`)
  },
})
