import { FileRoute, lazyRouteComponent } from '@tanstack/react-router'

import signed from '@/middlewares/signed'
import validateParams from '@/middlewares/validate-params'

export const Route = new FileRoute('/wearables/$wearableId').createRoute({
  component: lazyRouteComponent(() => import('@/modules/wearable/edit')),
  beforeLoad: ({ location, params, navigate }) => {
    signed({ location })

    validateParams(params, navigate, `/wearable/${params.wearableId}`)
  },
})
