import { createApi } from '@reduxjs/toolkit/query/react'

import { DEVICE_CATEGORY, DEVICE_CATEGORY_DETAIL, OLD_DEVICE_CATEGORY } from '@/config/endpoints'
import { BaseDetailRequest } from '@/types/common'
import {
  DeviceCategoryCreateRequest,
  DeviceCategoryDetailResponse,
  DeviceCategoryListRequest,
  DeviceCategoryListResponse,
  DeviceCategoryUpdateRequest,
} from '@/types/device-category'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'deviceCategory',
  tagTypes: ['DeviceCategory'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    createDeviceCategory: builder.mutation<void, DeviceCategoryCreateRequest>({
      query: (request) => ({
        ...composeRequest(request, DEVICE_CATEGORY),
        method: 'POST',
      }),
      invalidatesTags: ['DeviceCategory'],
    }),
    getListDeviceCategory: builder.query<DeviceCategoryListResponse, DeviceCategoryListRequest>({
      query: (request) => ({
        ...composeRequest(request, DEVICE_CATEGORY),
        method: 'GET',
      }),
      providesTags: ['DeviceCategory'],
    }),
    getDetailDeviceCategory: builder.query<DeviceCategoryDetailResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, DEVICE_CATEGORY_DETAIL),
        method: 'GET',
      }),
    }),
    updateDeviceCategory: builder.mutation<void, DeviceCategoryUpdateRequest>({
      query: (request) => ({
        ...composeRequest(request, DEVICE_CATEGORY_DETAIL),
        method: 'PUT',
      }),
      invalidatesTags: ['DeviceCategory'],
    }),
    getListOldDeviceCategory: builder.query<DeviceCategoryListResponse, DeviceCategoryListRequest>({
      query: (request) => ({
        ...composeRequest(request, OLD_DEVICE_CATEGORY),
        method: 'GET',
      }),
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useCreateDeviceCategoryMutation,
  useLazyGetListDeviceCategoryQuery,
  useLazyGetDetailDeviceCategoryQuery,
  useUpdateDeviceCategoryMutation,
  useLazyGetListOldDeviceCategoryQuery,
  util: deviceCategoryUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
