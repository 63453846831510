import { createApi } from '@reduxjs/toolkit/query/react'

import { ROLE, ROLE_COUNT, ROLE_DELETE_REPLACEMENT, ROLE_DETAIL, ROLE_PRIVILEGE } from '@/config/endpoints'
import { BaseDetailRequest } from '@/types/common'
import {
  RoleCountRequest,
  RoleCountResponse,
  RoleCreateRequest,
  RoleDetailResponse,
  RoleListRequest,
  RoleListResponse,
  RolePrivilegeListRequest,
  RolePrivilegeListResponse,
  RoleUpdateRequest,
} from '@/types/role'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'role',
  tagTypes: ['Role'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getListRole: builder.query<RoleListResponse, RoleListRequest>({
      queryFn: async (request, api, extraOptions, baseQuery) => {
        const { data, error } = await baseQuery({
          ...composeRequest(request, ROLE),
          method: 'GET',
        })

        if (error) {
          return { error }
        }

        const response: RoleListResponse = data as RoleListResponse

        for (const item of response.data.items) {
          const itemIdx = response.data.items.indexOf(item)
          const { data: roleCountData } = await baseQuery({
            ...composeRequest({ params: { xid: item.xid } }, ROLE_COUNT),
            method: 'GET',
          })

          if (roleCountData) {
            const roleCountResponse = roleCountData as RoleCountResponse

            response.data.items[itemIdx].count = roleCountResponse.data.count
          }
        }

        return {
          data: response,
        }
      },
      providesTags: ['Role'],
    }),
    getAllListRole: builder.query<RoleListResponse, RoleListRequest>({
      query: (request) => ({
        ...composeRequest(request, ROLE),
        method: 'GET',
      }),
    }),
    createRole: builder.mutation<void, RoleCreateRequest>({
      query: (request) => ({
        ...composeRequest(request, ROLE),
        method: 'POST',
      }),
      invalidatesTags: ['Role'],
    }),
    getDetailRole: builder.query<RoleDetailResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ROLE_DETAIL),
        method: 'GET',
      }),
    }),
    updateRole: builder.mutation<void, RoleUpdateRequest>({
      query: (request) => ({
        ...composeRequest(request, ROLE_DETAIL),
        method: 'PUT',
      }),
      invalidatesTags: ['Role'],
    }),
    deleteRole: builder.mutation<void, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ROLE_DETAIL),
        method: 'DELETE',
      }),
      invalidatesTags: ['Role'],
    }),
    deleteReplacementRole: builder.mutation<void, RoleCountRequest>({
      query: (request) => ({
        ...composeRequest(request, ROLE_DELETE_REPLACEMENT),
        method: 'DELETE',
      }),
      invalidatesTags: ['Role'],
    }),
    getListRolePrivilege: builder.query<RolePrivilegeListResponse, RolePrivilegeListRequest>({
      query: (request) => ({
        ...composeRequest(request, ROLE_PRIVILEGE),
        method: 'GET',
      }),
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useLazyGetListRoleQuery,
  useLazyGetAllListRoleQuery,
  useCreateRoleMutation,
  useLazyGetDetailRoleQuery,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
  useDeleteReplacementRoleMutation,
  useLazyGetListRolePrivilegeQuery,
  util: roleUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
