import { createApi } from '@reduxjs/toolkit/query/react'

import {
  ADMIN_LOCAL_PARTNER,
  ADMIN_LOCAL_PARTNER_CHANGE_STATUS,
  ADMIN_LOCAL_PARTNER_DETAIL,
  ADMIN_STORE,
} from '@/config/endpoints'
import { BaseDetailRequest, BaseErrorResponse } from '@/types/common'
import {
  LocalPartnerBulkCreateResponse,
  LocalPartnerBulkCreateResponseData,
  LocalPartnerChangeStatusRequest,
  LocalPartnerCreateRequest,
  LocalPartnerCreateRequestData,
  LocalPartnerDetailResponse,
  LocalPartnerExportResponse,
  LocalPartnerListRequest,
  LocalPartnerListResponse,
  LocalPartnerUpdateRequest,
} from '@/types/local-partner'
import { StoreListResponse } from '@/types/store'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'localPartner',
  tagTypes: ['LocalPartner'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    createLocalPartner: builder.mutation<void, LocalPartnerCreateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_LOCAL_PARTNER),
        method: 'POST',
      }),
      invalidatesTags: ['LocalPartner'],
    }),
    getListLocalPartner: builder.query<LocalPartnerListResponse, LocalPartnerListRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_LOCAL_PARTNER),
        method: 'GET',
      }),
      providesTags: ['LocalPartner'],
    }),
    getDetailLocalPartner: builder.query<LocalPartnerDetailResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_LOCAL_PARTNER_DETAIL),
        method: 'GET',
      }),
    }),
    updateLocalPartner: builder.mutation<void, LocalPartnerUpdateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_LOCAL_PARTNER_DETAIL),
        method: 'PUT',
      }),
      invalidatesTags: ['LocalPartner'],
    }),
    changeStatusLocalPartner: builder.mutation<void, LocalPartnerChangeStatusRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_LOCAL_PARTNER_CHANGE_STATUS),
        method: 'PUT',
      }),
      invalidatesTags: ['LocalPartner'],
    }),
    bulkCreateLocalPartner: builder.mutation<LocalPartnerBulkCreateResponse, LocalPartnerCreateRequestData[]>({
      queryFn: async (items, _api, _extraOptions, baseQuery) => {
        const responses: LocalPartnerBulkCreateResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: [],
        }

        for (const item of items) {
          const idx = items.indexOf(item)
          const response: LocalPartnerBulkCreateResponseData = {} as LocalPartnerBulkCreateResponseData

          const createLocalPartner: LocalPartnerCreateRequest = {
            data: {
              name: item.name,
              email: item.email,
            },
          }

          const { error } = await baseQuery({
            ...composeRequest(createLocalPartner, ADMIN_LOCAL_PARTNER),
            method: 'POST',
          })

          if (error) {
            response.row = idx + 1
            response.item = item
            response.error = error as BaseErrorResponse

            responses.data.push(response)
          }
        }

        if (responses.data.length > 0) {
          responses.success = false
        }

        return { data: responses }
      },
    }),
    exportListLocalPartner: builder.query<LocalPartnerExportResponse, LocalPartnerListRequest>({
      queryFn: async (request, _api, extraOptions, baseQuery) => {
        const data: LocalPartnerExportResponse = {
          success: true,
          code: 'OK',
          message: '',
          data: {
            items: [],
            metadata: {
              skip: '0',
              limit: '0',
              sortBy: 'latest',
              count: '0',
            },
          },
        }

        const limit = 100
        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const response = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    limit,
                    skip,
                    filters: {
                      ...(request.query?.filters || {}),
                    },
                  },
                },
                ADMIN_LOCAL_PARTNER
              ),
              method: 'GET',
            })

            const {
              data: { items },
            } = response.data as LocalPartnerListResponse

            for (const item of items) {
              const storeLimit = 100
              let storeSkip = 0
              let storeHasMore = true

              while (storeHasMore) {
                try {
                  const storeResponse = await baseQuery({
                    ...composeRequest(
                      {
                        query: {
                          limit: storeLimit,
                          skip: storeSkip,
                          filters: {
                            partnerId: item.id,
                          },
                        },
                      },
                      ADMIN_STORE
                    ),
                    method: 'GET',
                  })

                  const {
                    data: { items: storeItems },
                  } = storeResponse.data as StoreListResponse

                  if (storeItems.length > 0) {
                    for (const storeItem of storeItems) {
                      data.data.items.push({
                        localPartner: {
                          xid: item.xid,
                          name: item.name,
                          email: item.email,
                          statusName: item.status.name,
                        },
                        store: {
                          id: storeItem.store.id,
                          name: storeItem.store.name,
                          accountName: storeItem.store.account.name,
                          statusName: storeItem.store.status.name,
                        },
                      })
                    }
                  } else if (storeSkip === 0) {
                    data.data.items.push({
                      localPartner: {
                        xid: item.xid,
                        name: item.name,
                        email: item.email,
                        statusName: item.status.name,
                      },
                    })
                  }

                  storeSkip += storeLimit
                  storeHasMore = storeItems.length > 0
                } catch (e) {
                  console.error(e)
                }
              }
            }

            skip += limit
            hasMore = items.length > 0
          } catch (e) {
            console.error(e)
          }
        }

        return { data }
      },
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useCreateLocalPartnerMutation,
  useLazyGetListLocalPartnerQuery,
  useLazyGetDetailLocalPartnerQuery,
  useUpdateLocalPartnerMutation,
  useChangeStatusLocalPartnerMutation,
  useBulkCreateLocalPartnerMutation,
  useLazyExportListLocalPartnerQuery,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
