import download from '@/reducers/download'
import layout from '@/reducers/layout'
import upload from '@/reducers/upload'
import user from '@/reducers/user'

const rootReducers = {
  layout,
  user,
  upload,
  download,
}

export default rootReducers
