import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type UploadState = {
  isOpen: boolean
  isError: boolean
  isLoading: boolean
  items: Array<any>
  errors: Array<any>
}

const initialState: UploadState = {
  isOpen: false,
  isError: false,
  isLoading: false,
  items: [],
  errors: [],
}

export const uploadSlice = createSlice({
  initialState,
  name: 'upload',
  reducers: {
    setUploadIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
    setUploadIsError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload
    },
    setUploadIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setUploadItems: (state, action: PayloadAction<Array<any>>) => {
      state.items = action.payload
    },
    setUploadErrors: (state, action: PayloadAction<Array<any>>) => {
      state.errors = action.payload
    },
  },
})

export const { setUploadIsOpen, setUploadIsError, setUploadIsLoading, setUploadItems, setUploadErrors } =
  uploadSlice.actions

export default uploadSlice.reducer
