import { createApi } from '@reduxjs/toolkit/query/react'

import { ADMIN_PROFILE, ADMIN_USER, ADMIN_USER_DETAIL, ADMIN_USER_RESEND, ADMIN_USER_STATUS } from '@/config/endpoints'
import {
  AdminCreateRequest,
  AdminDetailResponse,
  AdminListRequest,
  AdminListResponse,
  AdminProfileResponse,
  AdminToggleStatusRequest,
  AdminUpdateRequest,
} from '@/types/admin'
import { BaseDetailRequest } from '@/types/common'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'admin',
  tagTypes: ['Admin'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    profile: builder.query<AdminProfileResponse, void>({
      query: () => ({
        ...composeRequest({}, ADMIN_PROFILE),
        method: 'GET',
      }),
    }),
    createAdmin: builder.mutation<void, AdminCreateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_USER),
        method: 'POST',
      }),
      invalidatesTags: ['Admin'],
    }),
    getListAdmin: builder.query<AdminListResponse, AdminListRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_USER),
        method: 'GET',
      }),
      providesTags: ['Admin'],
    }),
    getDetailAdmin: builder.query<AdminDetailResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_USER_DETAIL),
        method: 'GET',
      }),
    }),
    updateAdmin: builder.mutation<void, AdminUpdateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_USER_DETAIL),
        method: 'PUT',
      }),
      invalidatesTags: ['Admin'],
    }),
    deleteAdmin: builder.mutation<void, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_USER_DETAIL),
        method: 'DELETE',
      }),
      invalidatesTags: ['Admin'],
    }),
    toggleStatusAdmin: builder.mutation<void, AdminToggleStatusRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_USER_STATUS),
        method: 'PUT',
      }),
      invalidatesTags: ['Admin'],
    }),
    resendEmailAdmin: builder.mutation<void, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_USER_RESEND),
        method: 'POST',
      }),
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useLazyProfileQuery,
  useCreateAdminMutation,
  useLazyGetListAdminQuery,
  useLazyGetDetailAdminQuery,
  useUpdateAdminMutation,
  useDeleteAdminMutation,
  useToggleStatusAdminMutation,
  useResendEmailAdminMutation,
  util: adminUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
