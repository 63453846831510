import { createApi } from '@reduxjs/toolkit/query/react'

import { DeviceCategory } from '@/config/constants'
import { ADMIN_BRAND, ADMIN_WEARABLE, ADMIN_WEARABLE_DETAIL } from '@/config/endpoints'
import { BaseDetailRequest, BaseErrorResponse } from '@/types/common'
import {
  WearableBulkCreateRequestData,
  WearableBulkCreateResponse,
  WearableBulkCreateResponseData,
  WearableCreateRequest,
  WearableDetailResponse,
  WearableUpdateRequest,
} from '@/types/wearable'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'wearable',
  tagTypes: ['Wearable'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    createWearable: builder.mutation<void, WearableCreateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_WEARABLE),
        method: 'POST',
      }),
      invalidatesTags: ['Wearable'],
    }),
    getDetailWearable: builder.query<WearableDetailResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_WEARABLE_DETAIL),
        method: 'GET',
      }),
    }),
    updateWearable: builder.mutation<void, WearableUpdateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_WEARABLE_DETAIL),
        method: 'PUT',
      }),
      invalidatesTags: ['Wearable'],
    }),
    bulkCreateWearable: builder.mutation<WearableBulkCreateResponse, WearableBulkCreateRequestData[]>({
      queryFn: async (items, api, extraOptions, baseQuery) => {
        const responses: WearableBulkCreateResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: [],
        }

        for (const item of items) {
          const idx = items.indexOf(item)
          const response: WearableBulkCreateResponseData = {} as WearableBulkCreateResponseData

          let categoryId: DeviceCategory = DeviceCategory.UNKNOWN

          switch (item.categoryCode) {
            case 'SMARTWATCH':
            case 'smartwatch':
            case 'SmartWatch':
            case 'Smart Watch':
            case 'Smart watch':
            case 'smart watch':
              categoryId = DeviceCategory.SMARTWATCH
              break
            case 'EARBUD':
            case 'earbud':
            case 'EARBUDS':
            case 'earbuds':
              categoryId = DeviceCategory.EARBUD
              break
          }

          if (categoryId !== DeviceCategory.SMARTWATCH && categoryId !== DeviceCategory.EARBUD) {
            response.row = idx + 1
            response.item = item
            response.error = {
              status: 400,
              data: {
                message: 'Invalid Category',
                code: 'INTERNAL',
                success: false,
              },
            } as BaseErrorResponse

            responses.data.push(response)

            continue
          }

          const { error: getDetailDeviceBrandError } = await baseQuery({
            ...composeRequest(
              {
                params: {
                  xid: item.brandId,
                },
              },
              ADMIN_BRAND
            ),
            method: 'GET',
          })

          if (getDetailDeviceBrandError) {
            response.row = idx + 1
            response.item = item
            response.error = {
              status: 400,
              data: {
                message: 'Invalid Brand ID',
                code: 'INTERNAL',
                success: false,
              },
            } as BaseErrorResponse

            responses.data.push(response)

            continue
          }

          const createWearable: WearableCreateRequest = {
            data: {
              category: {
                id: categoryId,
              },
              brand: {
                xid: item.brandId,
              },
              marketingName: item.marketingName,
              modelCode: item.modelCode,
              modelType: item.modelType,
              ram: item?.ram ? Number(item.ram) : undefined,
              rom: item?.rom ? Number(item.rom) : undefined,
            },
          }

          const { error } = await baseQuery({
            ...composeRequest(createWearable, ADMIN_WEARABLE),
            method: 'POST',
          })

          if (error) {
            response.row = idx + 1
            response.item = item
            response.error = error.data as BaseErrorResponse

            responses.data.push(response)
          }
        }

        if (responses.data.length > 0) {
          responses.success = false
        }

        return { data: responses }
      },
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useCreateWearableMutation,
  useLazyGetDetailWearableQuery,
  useUpdateWearableMutation,
  useBulkCreateWearableMutation,
  util: deviceUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
