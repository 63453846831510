import { createApi } from '@reduxjs/toolkit/query/react'

import { ADMIN_BRAND, ADMIN_BRAND_DETAIL } from '@/config/endpoints'
import {
  BrandCreateRequest,
  BrandDetailResponse,
  BrandListRequest,
  BrandListResponse,
  BrandUpdateRequest,
} from '@/types/brand'
import { BaseDetailRequest } from '@/types/common'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'brand',
  tagTypes: ['Brand'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    createBrand: builder.mutation<void, BrandCreateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_BRAND),
        method: 'POST',
      }),
      invalidatesTags: ['Brand'],
    }),
    getListBrand: builder.query<BrandListResponse, BrandListRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_BRAND),
        method: 'GET',
      }),
      providesTags: ['Brand'],
    }),
    getDetailBrand: builder.query<BrandDetailResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_BRAND_DETAIL),
        method: 'GET',
      }),
    }),
    updateBrand: builder.mutation<void, BrandUpdateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_BRAND_DETAIL),
        method: 'PUT',
      }),
      invalidatesTags: ['Brand'],
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useCreateBrandMutation,
  useLazyGetListBrandQuery,
  useLazyGetDetailBrandQuery,
  useUpdateBrandMutation,
  util: brandUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
