import { AbilityBuilder, createMongoAbility, MongoAbility } from '@casl/ability'

import { Actions, Privileges } from '@/config/enums'

export type Ability = MongoAbility<[Actions, Privileges]>

export const ability = createMongoAbility

export const defineRules = (scopes: string[]) => {
  const { can, rules } = new AbilityBuilder<Ability>(ability)

  scopes.forEach((scope) => {
    can(Actions.Access, scope as Privileges)
  })

  return rules
}

export function buildAbility(scopes: string[]): Ability {
  return ability(defineRules(scopes), {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    detectSubjectType: (object: any) => object!.type,
  })
}
