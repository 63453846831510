import { FileRoute, lazyRouteComponent } from '@tanstack/react-router'
import { z } from 'zod'

import guest from '@/middlewares/guest'
import NotFound from '@/modules/error/not-found'

export const Route = new FileRoute('/auth/setup-password/').createRoute({
  component: lazyRouteComponent(() => import('@/modules/auth/setup-password')),
  beforeLoad: guest,
  validateSearch: (search) => z.object({ t: z.string() }).parse(search),
  errorComponent: () => <NotFound />,
})
