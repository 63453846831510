import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { sentryDSN, sentryEnvironment } from '@/config/env'
import { persistor, store } from '@/store'

import './styles/index.css'

import App from './App'
import i18next from './locales'

Sentry.init({
  dsn: sentryDSN,
  environment: sentryEnvironment,
  integrations: [],
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ReduxProvider store={store}>
    <PersistGate persistor={persistor}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </PersistGate>
  </ReduxProvider>
)
