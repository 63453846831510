import { Router, RouterProvider } from '@tanstack/react-router'
import { parse, stringify } from 'qs'
import React from 'react'

import AbilityContext from '@/contexts/AbilityContext'
import { Route as NotFoundRoute } from '@/routes/__error_404'
import { buildAbility } from '@/utils/abilities'
import { useAppSelector } from '@/utils/hooks'

import './styles/index.css'

import { routeTree } from './routeTree.gen'

const router = new Router({
  routeTree,
  defaultPreload: 'intent',
  caseSensitive: true,
  notFoundRoute: NotFoundRoute,
  stringifySearch: (search) => {
    const result = stringify(search, { encode: false })

    return result ? '?' + result : ''
  },
  parseSearch: (search) => parse(search.replace('?', '')),
})

const App = () => {
  const { data } = useAppSelector((state) => state.user)

  return (
    <AbilityContext.Provider value={buildAbility((data?.privileges || []).map((privilege) => privilege.xid))}>
      <RouterProvider router={router} />
    </AbilityContext.Provider>
  )
}

export default App
