import { createApi } from '@reduxjs/toolkit/query/react'
import { DateTime } from 'luxon'

import { DeviceConnectivity, DeviceIdentifierType, DeviceStatus, OrderType } from '@/config/constants'
import { ADMIN_DEVICE, ADMIN_DEVICE_DETAIL, DEVICE, DEVICE_CATEGORY_DETAIL, DEVICE_OLD } from '@/config/endpoints'
import { BaseDetailRequest, BaseErrorResponse, BaseListResponse, BaseResponse } from '@/types/common'
import {
  DeviceBulkCreateRequestData,
  DeviceBulkCreateResponse,
  DeviceBulkCreateResponseData,
  DeviceCreateRequest,
  DeviceDetailResponse,
  DeviceListRequest,
  DeviceListResponse,
  DeviceOld,
  DeviceUpdateRequest,
} from '@/types/device'
import { DeviceCategoryDetailResponse } from '@/types/device-category'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'device',
  tagTypes: ['Device'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    createDevice: builder.mutation<void, DeviceCreateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_DEVICE),
        method: 'POST',
      }),
      invalidatesTags: ['Device'],
    }),
    getListDevice: builder.query<DeviceListResponse, DeviceListRequest>({
      query: (request) => ({
        ...composeRequest(request, DEVICE),
        method: 'GET',
      }),
      providesTags: ['Device'],
    }),
    getListOldDevice: builder.query<BaseResponse<BaseListResponse<DeviceOld[]>>, DeviceListRequest>({
      query: (request) => ({
        ...composeRequest(request, DEVICE_OLD),
        method: 'GET',
      }),
      providesTags: ['Device'],
    }),
    getDetailDevice: builder.query<DeviceDetailResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_DEVICE_DETAIL),
        method: 'GET',
      }),
    }),
    updateDevice: builder.mutation<void, DeviceUpdateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_DEVICE_DETAIL),
        method: 'PUT',
      }),
      invalidatesTags: ['Device'],
    }),
    bulkCreateDevice: builder.mutation<DeviceBulkCreateResponse, DeviceBulkCreateRequestData[]>({
      queryFn: async (items, api, extraOptions, baseQuery) => {
        const responses: DeviceBulkCreateResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: [],
        }

        for (const item of items) {
          const idx = items.indexOf(item)
          const response: DeviceBulkCreateResponseData = {} as DeviceBulkCreateResponseData

          const releaseDate = DateTime.fromFormat(String(item.releaseDate), 'yyyy-MM-dd')

          if (!releaseDate.isValid) {
            response.row = idx + 1
            response.item = item
            response.error = {
              status: 400,
              data: {
                message: 'Invalid Release Date',
                code: 'INTERNAL',
                success: false,
              },
            } as BaseErrorResponse

            responses.data.push(response)

            continue
          }

          const getDetailDeviceCategory: BaseDetailRequest = {
            params: {
              xid: item.categoryCode,
            },
          }

          const { data: getDetailDeviceCategoryData, error: getDetailDeviceCategoryError } = await baseQuery({
            ...composeRequest(getDetailDeviceCategory, DEVICE_CATEGORY_DETAIL),
            method: 'GET',
          })

          if (getDetailDeviceCategoryError) {
            response.row = idx + 1
            response.item = item
            response.error = {
              status: 400,
              data: {
                message: 'Invalid Category Code',
                code: 'INTERNAL',
                success: false,
              },
            } as BaseErrorResponse

            responses.data.push(response)

            continue
          }

          const deviceCategoryData = getDetailDeviceCategoryData as DeviceCategoryDetailResponse

          let identifierTypeId = DeviceIdentifierType.UNKNOWN

          switch (deviceCategoryData.data.connectivity.id) {
            case DeviceConnectivity.WIFI_CELLULAR:
              identifierTypeId = DeviceIdentifierType.IMEI
              break
            case DeviceConnectivity.WIFI_ONLY:
            default:
              identifierTypeId = DeviceIdentifierType.SERIAL_NUMBER
              break
          }

          const statusId = item.status.toLowerCase().trim() === 'active' ? DeviceStatus.ACTIVE : DeviceStatus.INACTIVE
          // const releaseDateIsUpcoming = releaseDate.endOf('day').toSeconds() > DateTime.now().toSeconds()
          const OrderTypeId = item.orderType.toLowerCase().trim() === 'yes' ? OrderType.PRE_ORDER : OrderType.REGULAR

          const createDevice: DeviceCreateRequest = {
            data: {
              name: item.name,
              identifierNumber: item.identifierNumber,
              category: {
                code: item.categoryCode,
              },
              // status: {
              //   id: releaseDateIsUpcoming ? DeviceStatus.INACTIVE : statusId,
              // },
              status: {
                id: statusId,
              },
              orderType: {
                id: OrderTypeId,
              },
              identifierType: {
                id: identifierTypeId,
              },
              additionalIdentifierNumbers:
                identifierTypeId === DeviceIdentifierType.IMEI
                  ? [item.identifierNumber2, item.identifierNumber3, item.identifierNumber4]
                      .filter((item) => item && item.length > 0)
                      .map((item) => ({ identifierNumber: item }))
                  : [],
              modelType: item.modelType,
              modelCode: item.modelCode,
              ram:
                deviceCategoryData.data.connectivity.id !== DeviceConnectivity.NO_CONNECTIVITY
                  ? Number(item.ram)
                  : undefined,
              rom:
                deviceCategoryData.data.connectivity.id !== DeviceConnectivity.NO_CONNECTIVITY
                  ? Number(item.rom)
                  : undefined,
              releaseDate: releaseDate.startOf('day').toFormat('yyyy-MM-dd'),
            },
          }

          const { error } = await baseQuery({
            ...composeRequest(createDevice, ADMIN_DEVICE),
            method: 'POST',
          })

          if (error) {
            response.row = idx + 1
            response.item = item
            response.error = error.data as BaseErrorResponse

            responses.data.push(response)
          }
        }

        if (responses.data.length > 0) {
          responses.success = false
        }

        return { data: responses }
      },
    }),
    exportListDevice: builder.query<DeviceListResponse, DeviceListRequest>({
      queryFn: async (request, _api, _extraOptions, baseQuery) => {
        const data: DeviceListResponse = {
          success: true,
          code: 'OK',
          message: '',
          data: {
            items: [],
            metadata: {
              skip: '0',
              limit: '0',
              sortBy: 'latest',
              count: '0',
            },
          },
        }

        const newRequest: DeviceListRequest = { ...request }

        const limit = 100
        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const response = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit,
                    skip,
                  },
                },
                DEVICE
              ),
              method: 'GET',
            })

            const {
              data: { items },
            } = response.data as DeviceListResponse

            items.forEach((item) => {
              data.data.items.push(item)
            })

            skip += limit
            hasMore = items.length > 0
          } catch (e) {
            console.error(e)
          }
        }

        return { data }
      },
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useCreateDeviceMutation,
  useLazyGetListDeviceQuery,
  useLazyGetListOldDeviceQuery,
  useLazyGetDetailDeviceQuery,
  useUpdateDeviceMutation,
  useBulkCreateDeviceMutation,
  useLazyExportListDeviceQuery,
  util: deviceUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
