import { FileRoute, lazyRouteComponent } from '@tanstack/react-router'

import signed from '@/middlewares/signed'
import validateParams from '@/middlewares/validate-params'

export const Route = new FileRoute('/promotions/$promotionId/').createRoute({
  component: lazyRouteComponent(() => import('@/modules/promotion/detail')),
  beforeLoad: ({ location, params, navigate }) => {
    signed({ location })

    validateParams(params, navigate, `/promotions/${params.promotionId}`)
  },
})
